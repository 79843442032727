import { tw } from '@/utils/tw';
import BaseArticleTeaserDefaultTheme from 'base/components/ArticleTeaser/Default/theme';

const { Image } = BaseArticleTeaserDefaultTheme;

const ArticleTeaserDefault = tw.theme({
  extend: BaseArticleTeaserDefaultTheme,
  slots: {
    base: ['group-[&:not(.bg)]/box:bg-white'],
    caption: [
      'uppercase',
      'text-headline-xs',
      'text-primary-700',
      'group-[.bg-blue-100]/box:text-blue-700',
      'group-[.bg-primary-100]/box:text-primary-700',
      'group-[.bg-yellow-100]/box:text-yellow-800',
    ],
    description: ['!text-body-sm', '!mt-0'], // override container-col and text-body bug in tw-merge
  },
  variants: {
    variant: {
      vertical: {
        base: [
          'relative',
          'gap-0',
          'group-[&[data-title-position=titleLeftSideOfImage]]/box:grid-cols-[53%_auto]',
          'group-[&[data-title-position=titleRightSideOfImage]]/box:grid-cols-[auto_53%]',
          'group-[&[data-title-position=titleLeftSideOfImage]]/box:items-center',
          'group-[&[data-title-position=titleRightSideOfImage]]/box:items-center',
          'group-[&[data-title-position=titleLeftSideOfImage]]/box:[&>a]:order-2',
        ],
        footer: ['col-span-full'],
        group: [
          'gap-y-0.5',
          'px-1',
          'pt-3',
          'pb-4',

          'group-[.bg]/box:px-3',
          'group-[&[data-title-position]]/box:py-0',

          'container-col-sm-8:px-3',
          'container-col-sm-8:py-4',

          'sm:gap-y-0',
          'sm:container-col-sm-8:pt-3.5',
        ],
        header: [
          'absolute',
          'left-0',
          'top-0',
          'z-1',
          'col-span-full',
          'flex',
          'max-w-full',
          'flex-row',
          'items-center',
          'uppercase',
          'sm:mt-1',
        ],
        headline: [
          'text-headline-sm',
          'container-col-sm-7:text-headline-sm',
          'container-col-sm-8:text-headline-lg',

          'sm:text-headline-lg',
          'sm:container-col-lg-7:text-headline-lg',
          'sm:container-col-lg-10:text-headline-xl',
        ],
        caption: ['mb-0.5', 'sm:mb-1.5'],
      },
    },
  },
});

export default Object.assign(ArticleTeaserDefault, { Image });
